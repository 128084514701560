import * as React from 'react';
import { useEffect } from 'react';
import * as Styled from '../styles';
import { graphql } from 'gatsby';
import GlobalStyles from '../styles/globalStyles';
import { HtmlSiteMapHeader, MainContainer, HtmlSitemapLink } from '../styles';
import PageHeader from '../components/dynamic/pageHeader/pageHeader';
import Footer from '../components/footer/footer';
import TopBar from '../components/topBar/topBar';
import NavigationBar from '../components/navigationBar/navigationBar';
import FooterLinks from '../components/footerLinks/footerLinks';
import { Helmet } from 'react-helmet';
import { addTrailingSlash } from '../utils';

const HTMLSiteMap = ({ data }) => {
  const allSlugs = [
    ...data?.allContentfulBurnabyRefinery?.edges
      ?.filter((edge) => {
        return edge?.node?.slug && edge;
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(edge?.node?.slug),
          title: edge?.node?.title,
        };
      }),
  ].sort((a, b) => a?.slug?.localeCompare(b?.slug));

  const SlugList = allSlugs.filter(function (x, i, a) {
    return a.indexOf(x) === i;
  });
  return (
    <>
      <Helmet>
        <title>Site Map</title>
      </Helmet>
      <GlobalStyles />
      <TopBar desktop />
      <Styled.Clear />
      <NavigationBar />
      <PageHeader header="SITE MAP" />
      <MainContainer style={{ paddingTop: '20px', marginTop: '-1px' }}>
        {SlugList.map((slug, index) => (
          <HtmlSiteMapHeader
            $leftSpace={slug?.slug?.split('/')?.length - 1}
            key={index}
          >
            <HtmlSitemapLink href={slug?.slug}>{slug.title}</HtmlSitemapLink>
          </HtmlSiteMapHeader>
        ))}
      </MainContainer>
      <FooterLinks />
      <Footer />
    </>
  );
};

export default HTMLSiteMap;

export const pageQuery = graphql`
  query HTMLSiteMapQuery {
    allContentfulBurnabyRefinery(
      filter: { node_locale: { eq: "en-US" }, hasIndex: { ne: false } }
    ) {
      edges {
        node {
          slug
          title
          name
        }
      }
    }
  }
`;
